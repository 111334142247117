import './Footer.css'

export default function Footer() {
    return (
        <div className="footer">
            <div className="main-address">
                <h3 className="main-text">
                    Let’s create amazing things! hi@dutkowski.com
                </h3>
            </div>
        </div>
    );
}