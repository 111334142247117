import './App.css';
import {
  createBrowserRouter, createRoutesFromElements, Route,
  RouterProvider,
} from "react-router-dom";
import React from 'react';
import RootLayout from "./RootLayout";
import About from './components/about/About';
import Work from './components/work/Work';
import Main from './components/main/Main';
import ErrorPage from './components/error/Error';
import { projectLinks } from './projects';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route path="" element={<Main />}></Route>
      <Route path="about" element={<About />} ></Route>
      <Route path="work" element={<Work />}></Route>
      {createProjectRoutes(projectLinks)}
      <Route path="*" element={<ErrorPage />}></Route>
    </Route>
  )
)

function createProjectRoutes(projectLinks) {
  return projectLinks.map(project => (
    <Route key={project.id} path={`project/${project.name}`} element={project.component} />
  ));
}

export default function App() {
  return (
    <div className="App">
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </div>
  );
}