import './Work.css';
import { NavLink } from "react-router-dom";
import { projectLinks } from '../../projects';



export default function WorkItem({ id, clientName, shortDescription, name }) {
    const log = () => {
        // console.log("id: " + id + ", clientName: " + clientName + ", shortDescription: " + shortDescription + ", name: " + name)
        console.log(`/proj${id}/image.png`);

    }

    return (
        < div className="col-lg-4 col-xl-4 col-md-6" >
            <div className="custom-work-container">
                <div className="works-img-wrapper">
                    <img className="work-img" src={`img/proj${id}/image.png`} alt="" />
                </div>
                <NavLink className="img-overlay" to={`/project/${name}`}>
                    <h3 className="overlay-header">{id}/{projectLinks.length}</h3>
                    <div className="overlay-footer">
                        <h4>Client: {clientName}</h4>
                        <p>{shortDescription}</p>
                    </div>
                </NavLink>
            </div>
        </div >
    );
}