import './Work.css';
import { projectLinks } from '../../projects';
import WorkItem from './WorkItem';

const getProjects = () => {
    return projectLinks.map(props => <WorkItem {...props} key={props.id} />);
}

export default function Work() {
    return (
        <div className="works-container">
            <div className="main-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        {getProjects()}
                    </div>
                </div>
            </div>
        </div >
    );
}