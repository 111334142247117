import Pzu from './components/projects/Pzu';
import Repropol from './components/projects/Repropol';
import Medicine from './components/projects/Medicine';
import Mass from './components/projects/Mass';
import Majsteria from './components/projects/Majsteria';

class Project {
    constructor({ id, clientName, shortDescription, name, layout, component }) {
        this.id = id;
        this.clientName = clientName;
        this.shortDescription = shortDescription;
        this.name = name;
        this.layout = layout;
        this.component = component;
    }
}

export const projectLinks = [
    new Project({
        id: 1,
        clientName: 'Repropol',
        shortDescription: 'Polish cosmetics brand',
        name: 'repropol',
        layout: [0, 4, 8],
        component: <Repropol />
    }),
    new Project({
        id: 2,
        clientName: 'Medicine',
        shortDescription: 'Polish cosmetics brand',
        name: 'medicine',
        layout: [7, 5, 0],
        component: <Medicine />
    }),
    new Project({
        id: 3,
        clientName: 'Pzu',
        shortDescription: 'short description',
        name: 'pzu',
        layout: [1, 4, 7],
        component: <Pzu />
    }),
    new Project({
        id: 4,
        clientName: 'Mass',
        shortDescription: 'short description',
        name: 'mass',
        layout: [7, 5, 0],
        component: <Mass />
    }),
    new Project({
        id: 5,
        clientName: 'Majsteria',
        shortDescription: 'short description',
        name: 'majsteria',
        layout: [2, 4, 4],
        component: <Majsteria />
    }),
]