import './About.css';
import Footer from '../main/Footer';

export default function About() {
    return (
        <div className="common-container">
            <div className="main-wrapper">
                <div className="container-fluid about g-0">
                    <div className="row g-0">
                        <div className="col-md-12 col-lg-6 col-xl-6">
                            <h3>
                                I've created many brands & thousands of pages in brand manuals.
                            </h3>
                        </div>
                        <div className="col-md-12 col-lg-6 col-xl-6 about-text">
                            <p>
                                Hello! I'm Adrian, a passionate Graphic Designer and Art
                                Director based in Poland. With over 18 years of experience in
                                the creative industry, I specialize in creating visually
                                stunning and impactful designs that tell a story.
                            </p>
                            <p>
                                My journey into the world of design began with a deep love for
                                art and visual storytelling. Over the years, I've honed my
                                skills and developed a unique approach to design, blending
                                creativity with strategic thinking to deliver exceptional
                                results. From branding and logo design to web and print media,
                                my work spans a wide range of mediums and styles.
                            </p>
                            <p>
                                I believe that great design is not just about aesthetics; it's
                                about solving problems and creating meaningful connections. My
                                goal is to bring your vision to life, crafting designs that not
                                only look beautiful but also resonate with your audience and
                                drive engagement.
                            </p>
                            <p>
                                I create cohesive and compelling visual identities that set you
                                apart, leading creative projects from concept to completion,
                                ensuring that every element aligns with your brand's vision and
                                goals. I design user-friendly and visually appealing websites
                                that provide an exceptional user experience and drive
                                conversions. From brochures to posters, I bring your ideas to
                                life with high-quality print materials that leave a lasting
                                impression.
                            </p>
                            <p>
                                I am always excited to take on new challenges and collaborate
                                with clients who are as passionate about their projects as I am.
                                Whether you're a startup looking to build your brand from the
                                ground up or an established company in need of a fresh
                                perspective, I am here to help you achieve your goals.
                            </p>
                            <p>
                                Feel free to browse through my portfolio to see some of my past
                                work. If you're interested in working together or just want to
                                say hello, don't hesitate to get in touch!
                            </p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}