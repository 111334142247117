import { Fragment } from 'react';
import './Main.css';
import { projectLinks } from '../../projects';
import ListElement from './ListElement';
import { NavLink } from 'react-router-dom';
import Footer from './Footer';

export default function Main() {

    const getProjects = () => {
        return projectLinks.map(props => <ListElement {...props} key={props.id} />);
    }

    return (
        <Fragment>
            <div className="body-container">
                <div className="main-wrapper">
                    <h3 className="main-text">
                        I’m an independent designer & art director, dedicated to crafting
                        unique and impactful creative solutions. With years of experience in
                        the industry, I blend innovation and strategic thinking to bring
                        visionary ideas to life.
                    </h3>
                    {/* <div className="footer">
                        <div className="main-address">
                            <h3 className="main-text">
                                Let’s create amazing things! hi@dutkowski.com
                            </h3>
                        </div>
                    </div> */}
                    <Footer />
                </div>
            </div>
            <main className="works-list">
                <div className="main-wrapper">
                    <div className="container-fluid">
                        {getProjects()}
                        <div className="row footer-button">
                            <div className="col-8 footer-button"></div>
                            <div className="col-4 footer-button">
                                <NavLink className="button-h-a" to="/work">
                                    <div className="button-h">
                                        <h3 className="button-text-h">See all {projectLinks.length} works</h3>
                                        <h3 className="button-text-h-overlay">See all 🔥 works</h3>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </Fragment>
    );
}