import './Project.css';
import WorkNav from '../nav/WorkNav';

export default function Mass() {
    return (
        <div className="common-container">
            <div className="main-wrapper">
                <div className="container-fluid g-0">
                    <div className="row g-0">
                        <div className="col-6">
                            <h2 className="proj-header">MASS</h2>
                        </div>
                        <div className="col-6 proj-desc">
                            <h3 className="proj-desc-title">Description</h3>
                            <p className="proj-desc-text">
                                Birdie (formerly known as Canairi)is a visual Fresh Air Monitor
                                thathelps people worldwide achieve a better indoor climate. When
                                thebird dies, it's time to open your win.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <img
                                className="proj_img"
                                src="/img/pzu/01_16x9_F.webp"
                                alt="pzu_01_16x9_F"
                            />
                        </div>
                        <div className="col-6">
                            <img
                                className="proj_img"
                                src="/img/pzu/02_1x1_H.gif"
                                alt="pzu_02_1x1_H"
                            />
                        </div>
                        <div className="col-6">
                            <img
                                className="proj_img"
                                src="/img/pzu/03_1x1_H.webp"
                                alt="pzu_03_1x1_H"
                            />
                        </div>
                        <div className="col-6">
                            <img
                                className="proj_img"
                                src="/img/pzu/04_3x4_H.webp"
                                alt="pzu_04_3x4_H"
                            />
                        </div>
                        <div className="col-6">
                            <img
                                className="proj_img"
                                src="/img/pzu/05_3x4_H.webp"
                                alt="pzu_05_3x4_H"
                            />
                        </div>
                        <div className="col-6">
                            <img
                                className="proj_img"
                                src="/img/pzu/06_1x1_H.webp"
                                alt="pzu_06_1x1_H"
                            />
                        </div>
                        <div className="col-6">
                            <img
                                className="proj_img"
                                src="/img/pzu/09_3x4_H.webp"
                                alt="pzu_09_3x4_H"
                            />
                        </div>
                        <div className="col-12">
                            <img
                                className="proj_img"
                                src="/img/pzu/10_2x3_F.webp"
                                alt="pzu_10_2x3_F"
                            />
                        </div>
                        <div className="col-12">
                            <img
                                className="proj_img"
                                src="/img/pzu/11_2x3_F.webp"
                                alt="pzu_11_2x3_F"
                            />
                        </div>
                        <div className="col-12">
                            <img
                                className="proj_img"
                                src="/img/pzu/12_2x3_F.webp"
                                alt="pzu_12_2x3_F"
                            />
                        </div>
                        <div className="col-6">
                            <img
                                className="proj_img"
                                src="/img/pzu/13_2x3_H.webp"
                                alt="pzu_13_2x3_H"
                            />
                        </div>
                        <div className="col-6">
                            <img
                                className="proj_img"
                                src="/img/pzu/14_2x3_H.webp"
                                alt="pzu_14_2x3_H"
                            />
                        </div>
                    </div>
                    <WorkNav current='4' />
                </div>
            </div>
        </div>
    );
}