import './Project.css';
import WorkNav from '../nav/WorkNav';


export default function Medicine() {
    return (
        <div className="common-container">
            <div className="main-wrapper">
                <div className="container-fluid g-0">
                    <div className="row g-0">
                        <div className="col-6">
                            <h2 className="proj-header">Medicine as everdyday theraphy</h2>
                        </div>
                        <div className="col-6 proj-desc">
                            <h3 className="proj-desc-title">Description</h3>
                            <p className="proj-desc-text">
                                Birdie (formerly known as Canairi) is a visual Fresh Air Monitor
                                thathelps people worldwide achieve a better indoor climate. When
                                thebird dies, it's time to open your win.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <img
                                className="proj_img"
                                src="../img/medicine/01_16x9_F.webp"
                                alt="medicine_01_16x9_F"
                            />
                        </div>
                        <div className="col-12">
                            <img
                                className="proj_img"
                                src="../img/medicine/02_2x3_F.webp"
                                alt="medicine_02_2x3_F"
                            />
                        </div>
                        <div className="col-12">
                            <img
                                className="proj_img"
                                src="../img/medicine/03_2x3_F.webp"
                                alt="medicine_03_2x3_F"
                            />
                        </div>
                        <div className="col-12">
                            <img
                                className="proj_img"
                                src="../img/medicine/04_2x3_F.webp"
                                alt="medicine_04_2x3_F"
                            />
                        </div>
                        <div className="col-6">
                            <img
                                className="proj_img"
                                src="../img/medicine/05_3x4_H.webp"
                                alt="medicine_05_3x4_H"
                            />
                        </div>
                        <div className="col-6">
                            <img
                                className="proj_img"
                                src="../img/medicine/06_3x4_H.webp"
                                alt="medicine_06_3x4_H"
                            />
                        </div>
                        <div className="col-12">
                            <img
                                className="proj_img"
                                src="../img/medicine/07_2x3_F.webp"
                                alt="medicine_07_2x3_F"
                            />
                        </div>
                        <div className="col-12">
                            <img
                                className="proj_img"
                                src="../img/medicine/08_2x3_F.webp"
                                alt="medicine_08_2x3_F"
                            />
                        </div>
                        <div className="col-6">
                            <img
                                className="proj_img"
                                src="../img/medicine/09_1x1_H.webp"
                                alt="medicine_09_1x1_H"
                            />
                        </div>
                        <div className="col-6">
                            <img
                                className="proj_img"
                                src="../img/medicine/10_1x1_H.webp"
                                alt="medicine_10_1x1_H"
                            />
                        </div>
                        <div className="col-12">
                            <img
                                className="proj_img"
                                src="../img/medicine/11_2x3_F.webp"
                                alt="medicine_11_2x3_F"
                            />
                        </div>
                    </div>
                    <WorkNav current='2' />
                </div>
            </div>
        </div>
    );
}