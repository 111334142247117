import * as React from 'react';
import { NavLink } from "react-router-dom";


function ResponsiveAppBar() {

    return (
        <div className="header-container">
            <nav className="nav-container">
                <div className="left-nav">
                    <NavLink className="active" to="/">Adrian Dutkowski</NavLink>
                </div>
                <div className="right-nav">
                    <NavLink to="/work">Work</NavLink>
                    <NavLink to="/about">About</NavLink>
                    <NavLink to="/contact">Email</NavLink>
                </div>
            </nav>
        </div>
    );
}

export default ResponsiveAppBar;