import { NavLink } from "react-router-dom";
import { projectLinks } from '../../projects';

export default function ListElement({ id, clientName, shortDescription, name, layout }) {

    const getInterspace = (number) => {
        if (number > 0) {
            return <div className={`col-${number}`}></div>
        }
    }

    return (
        <div className="row list-elem">
            {getInterspace(layout[0])}
            <div className={`col-${layout[1]}`}>
                <div className="custom-main-img-container">
                    <div className="main-img-wrapper">
                        <img className="main-img" src={`img/proj${id}/image.png`} alt='front' />
                    </div>
                    <NavLink className="main-img-overlay" to={`/project/${name}`}>
                        <h3 className="overlay-header">{id}/{projectLinks.length}</h3>
                        <div className="overlay-footer">
                            <h4>Client: {clientName}</h4>
                            <p>{shortDescription}</p>
                        </div>
                    </NavLink>
                </div>
            </div>
            {getInterspace(layout[2])}
        </div>
    );
}