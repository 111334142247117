import React from "react";
import './WorkNav.css';
import { NavLink } from "react-router-dom";
import { projectLinks } from "../../projects";

export default function WorkNav({ current }) {

    const getLinkToPrev = (currentIndex) => {
        if (currentIndex <= 1) {
            return `/project/${projectLinks[projectLinks.length - 1].name}`;
        }
        return `/project/${projectLinks[currentIndex - 2].name}`;
    }

    const getLinkToNext = (currentIndex) => {
        if (currentIndex >= projectLinks.length) {
            return `/project/${projectLinks[0].name}`;
        }
        return `/project/${projectLinks[currentIndex].name}`;
    }

    return (
        <div className="work-nav-wrapper">
            <div className="work-nav">
                <NavLink to={getLinkToPrev(current)}>
                    <div className="arrow-left arrow">
                        <div className="line"></div>
                        <div className="head-upper-line"></div>
                        <div className="head-lower-line"></div>
                    </div>
                </NavLink>
                <p className="nav-couter">{current}/{projectLinks.length}</p>
                <NavLink to={getLinkToNext(current)}>
                    <div className="arrow-right arrow">
                        <div className="line"></div>
                        <div className="head-upper-line"></div>
                        <div className="head-lower-line"></div>
                    </div>
                </NavLink>
            </div>
        </div >
    );
}